import { messageTypeRegistry } from "@sp-crm/core";
import { SignatureFieldType } from "generated/graphql";

export const signatureFonts = {
    sansSerif: "Roboto",
    cursive: "Parisienne",
};

export const getFontForFieldType = (fieldType: SignatureFieldType) => {
    if (fieldType === SignatureFieldType.Signature) {
        return signatureFonts.cursive;
    }
    if (fieldType === SignatureFieldType.Initial) {
        return signatureFonts.cursive;
    }
    return signatureFonts.sansSerif;
};

export const compareSignatureTemplateFields = (
    a: { order: number; page: number; x: number; y: number },
    b: { order: number; page: number; x: number; y: number },
) => {
    if (a.page !== b.page) {
        return a.page - b.page;
    }

    if (Math.abs(a.y - b.y) > 10) {
        return a.y - b.y;
    }

    if (a.x !== b.x) {
        return a.x - b.x;
    }

    return a.order - b.order;
};

interface ValidateBodyResponse {
    isValid: boolean;
    errorMessage: string | null;
}

export const validateSignatureRequestBody = (body: string): ValidateBodyResponse => {
    const messageMetadata = messageTypeRegistry["SignatureRequest"];

    const response: ValidateBodyResponse = {
        isValid: true,
        errorMessage: null,
    };

    if (!messageMetadata) {
        return response;
    }

    for (const token of Object.keys(messageMetadata.tokens)) {
        if (!(body || "").includes(`{{${token}}}`)) {
            response.isValid = false;
            response.errorMessage = `The email body must include {{${token}}} so the recipient can have a link to sign the document. Once you have added {{${token}}}, you will be able to proceed.`;
            return response;
        }
    }

    return response;
};
